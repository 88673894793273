/*======================
10. Services Style CSS
========================*/

.service-area-top {
  height: 1300px;
  padding: 180px 0 130px;

  @media (max-width: 2560px) {
    height: 1100px;
    padding: 100px 0 120px;
  }
  @media (max-width: 1980px) {
    height: 100vh;
    padding: 100px 0 120px;
  }
  @media (max-width: 1600px) {
    height: 100vh;
    padding: 100px 0 120px;
  }

  @media (max-width: 1440px) {
    height: 800px;
    padding: 90px 0 110px;
  }

  @media (max-width: 1200px) {
    height: 925px;
    padding: 80px 0 100px;
  }

  @media (max-width: 1024px) {
    height: 1200px;
    padding: 70px 0 90px;
  }
  @media (max-width: 980px) {
    height: 1200px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: none!important;
  }
  @media (max-width: 768px) {
    height: 1200px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: none!important;
  }

  @media (max-width: 576px) {
    height: 1200px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: none!important;
  }

  @media (max-width: 452px) {
    height: 1200px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: none!important;
  }
}

.service-content-area {
  
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
 

  @media (max-width: 10000px) {
    margin-top: -360px;
  }
  @media (max-width: 2560px) {
    margin-top: -360px;
  }
  @media (max-width: 1980px) {
    margin-top: -300px;
  }
  @media (max-width: 1900px) {
    margin-top: -300px;
  }
  @media (max-width: 1600px) {
    margin-top: -260px;
  }
  @media (max-width: 1440px) {
    margin-top: -260px;
  }

  @media (max-width: 1200px) {
    margin-top: -470px;
  }

  @media (max-width: 1024px) {
    margin-top: -800px;
  }
  @media (max-width: 980px) {
    margin-top: -850px;
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    margin-top: -890px;
    padding-bottom: 100px;
  }
  @media (max-width: 670px) {
    margin-top: -920px;
    padding-bottom: 100px;
  }

  @media (max-width: 576px) {
    margin-top: -900px;
    padding-bottom: 100px;
  }
  // @media (max-width: 476px) {
  //   margin-top: -1000px;
  //   padding-bottom: 100px;
  // }
  
  @media (max-width: 452px) {
    margin-top: -860px;
    padding-bottom: 80px;
  }
  @media (max-width: 420px) {
    margin-top: -860px;
    padding-bottom: 100px;
  }


}


.service-item {
  margin-top: 30px;
  transition: 0.4s;
  position: relative;
  text-align: center;
  overflow: hidden;

  .service-txt {
    background-color: $offWhite;
    padding: 25px 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    h3 {
      font-weight: 600;
      margin-bottom: 0;

      @media #{$lg-device, $xl-device} {
        font-size: 1.25rem;
      }

      @media #{$sm-device, $md-device} {
        font-size: 16px;
      }
    }

    h5 {
      font-weight: 600;
      margin-bottom: 0;

      @media #{$sm-device} {
        font-size: 16px;
      }
    }
  }

  .service-txt {
    min-height: 100px;
  }

  .service-txt {
    @media #{$sm-device} {
      min-height: 80px;
    }
  }



  .service-content {
    background-color: rgba($brandColor, 0.9);
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    transition: 0.2s;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    @media #{$sm-device} {
      padding: 15px;
    }

    h5 {
      color: $white;
      font-weight: 500;
      margin-bottom: 18px;

      a {
        color: $white;
      }
    }
  }

  &:hover {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26);

    .service-content {
      left: 0;
    }
  }
}

.non-opacity {

  &:after,
  &:before {
    display: none;
  }
}


.build-something-with-us-thumb {
  overflow: hidden;
  border-radius: 20px;

  @media #{$sm-device} {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  img {
    width: 100%;
    transition: 0.4s;
  }

  &--2 {
    margin-right: -250px;
  }
}

.service-item:hover {
  border-radius: 5%;
}

.service-thumb img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}