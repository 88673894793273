/*====================
07. Slider Style CSS
=======================*/

.slider-content{

  @media (max-width: 10000px) {
    height: 100vh;
  }
  @media (max-width: 1200px) {
    height: 650px;
  }
  @media (max-width: 1024px) {
    height: 650px;
  }
  @media (max-width: 980px) {
    height: 650px;
  }
}

.slider {
  &-item {
    background-size: cover;
    background-position: center top;
    display: flex !important;
    align-items: center;
    height: 950px;
    background-repeat: no-repeat;
    text-align: center;
    justify-content: center;


    @media #{$md-device} {
      height: 600px;
    }

    @media #{$sm-device} {
      height: 500px;
      margin-top: 25px;
    }
  }

  &-content {
    font-size: 20px;
    font-weight: 400;
    margin: auto;
    color: $lightGrayishBlue;
    padding: 20px;

    @media #{$md-device,$lg-device} {
      max-width: 100%;
    }

    @media #{$sm-device} {
      max-width: 100%;
    }

    & p:last-child{
      letter-spacing: -0.44px;
    }

    h2 {
      font-size: 60px;
      font-weight: 600;
      color: #ffffff;

      @media #{$md-device,$lg-device} {
        font-size: 45px;
      }

      @media #{$sm-device} {
        font-size: 30px;

        br {
          display: block;
        }
      }
    }

    p {
      @media #{$xl-device} {
        // max-width: 750px;
      }
    }

    .slider-item-text {
      @media #{$xl-device, $md-device, $sm-device, $lg-device} {
        color: #e42326;
      }
    }

    .slider-item-text-2 {
      @media #{$sm-device} {
        display: none;
      }
    }
    .slider-item-text-subheader{
      font-size: 32px;
      font-weight: 500;
    }

    .slider-subheader{
      // font-size: clamp(20px, 1.667vw, 32px);
      
      @media (max-width: 10000px) {
        font-size: 20px;
      }
      @media (max-width: 1980px) {
    
      }
      @media (max-width: 1600px) {
        
      }
      @media (max-width: 1400px) {
        
      }
      @media (max-width: 1200px) {
        
      }
      @media (max-width: 1024px) {
        
      }
      @media (max-width: 896px) {
        font-size: 18px;
        max-width: 100vw;
        line-height: 20px;
        margin-bottom: 25px;
        margin-top: 45px;
      }
      @media (max-width: 768px) {
        font-size: 20px;
        max-width: 100vw;
        line-height: 20px;
        margin-bottom: 25px;
        margin-top: 45px;
      }
      @media (max-width: 576px) {
      
      }
      @media (max-width: 420px) {
      
      }

    }
    .slider-paragraph{
      
      
      
      @media #{$sm-device} {
        font-size: 16px;
      }
      @media (max-width: 10000px) {
        max-width: 35vw;
        font-size: 1.042vw;
        line-height: 1.7vw;
      }
      // @media (max-width: 1980px) {
    
      // }
      // @media (max-width: 1600px) {
        
      // }
      // @media (max-width: 1400px) {
        
      // }
      @media (max-width: 1200px) {
        
      }
      @media (max-width: 1024px) {
        
      }
      @media (max-width: 768px) {
        font-size: 16px;
        max-width: 100vw;
        line-height: 20px;
      }
      @media (max-width: 576px) {
      
      }
      @media (max-width: 420px) {
      
      }
    }
   

    .btn {
      margin-top: 30px;


      @media #{$sm-device} {
        margin-top: 20px;
      }
    }

    &--2 {
      h2 {
        @media #{$xl-device,$lg-device} {
          font-size: 55px;
        }
      }
    }

    &.light {
      color: $white;

      h2 {
        color: $white;
      }
    }
  }
}

.slider-area {
  position: relative;

  .slick-arrow {
    color: $blackSoft;
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
    font-size: 70px;
    z-index: 9;

    @media #{$sm-device} {
      font-size: 40px;
      left: 30px;
    }

    &.slick-next {
      left: auto;
      right: 50px;

      @media #{$sm-device} {
        right: 30px;
      }
    }
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    margin-top: 0;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  &--2 {
    .slick-arrow {
      color: $white;
    }
  }
}


.container-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 200px;
  overflow: hidden;
  background-color: #f4f4f4;
}

.arrow-animation {
  width: 50px;
  height: 10px;
  background-color: #333;
  position: absolute;
}

.arrow-left-animation {
  left: 10%;
}

.arrow-right-animation {
  right: 10%;
}

.text-animation {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000;
  z-index: 1;
}
