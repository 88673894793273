.partners-heading{

	@media (max-width: 10000px) {
	  margin-bottom: 60px;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  text-align: center;
	}
	@media (max-width: 2560px) {
	  font-size: 2.5vw;
	  margin-bottom: 60px;
	}
	@media (max-width: 991px) {
	  font-size: 40px;
	  margin-bottom: 40px;
	}
	@media (max-width: 768px) {
	  font-size: 36px;
	}
  }
  .clients-and-partners-h2{
	@media (max-width: 10000px) {
	  font-size: 48px;
	}
	@media (max-width: 2560px) {
	  font-size: 2.5vw;
	}
	@media (max-width: 991px) {
	  font-size: 40px;
	}
  }
  .partners-heading p{
	
  
	@media (max-width: 10000px) {
	  font-size: 20px;
	  max-width: 760px;
	  color: rgba(255, 255, 255, 0.75);
	}
	@media (max-width: 2560px) {
	  font-size: 1.146vw;
	  max-width: 45vw;
	}
	@media (max-width: 991px) {
	  font-size: 18px;
	  max-width: 100%;
	}
	@media (max-width: 768px) {
	  font-size: 16px;
	}
  }
  .partners-grid {
	
	@media (max-width: 10000px) {
	  display: flex;
	  justify-content: center;
	  gap: 105px;
	  padding-left: 160px;
	}
	@media (max-width: 2560px) {
	  gap: 5.102vw;
	  padding-left: 6.25vw;
	}
	@media (max-width: 1980px) {
	  gap: 5.469vw;
	  padding-left: 8.333vw;
	}
	@media (max-width: 991px) {
	  display: inline-grid;
	  grid-template-columns: repeat(4, 1fr); 
	  gap: 20px;
	  padding-left: 0px;
	}
	@media (max-width: 568px) {
	  grid-template-columns: repeat(2, 1fr); 
	}
  }
  
  .column {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  gap: 20px;
	  transform: rotate(-45deg);
  
	  @media (max-width: 991px) {
		transform: none;
		display: flex;
		flex-direction: column;
		align-items: center;
	  }
  }
  
  .diamond-container {
	  
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  border-radius: 20px;
	  position: relative;
	  overflow: hidden;
	  transition: background-color 0.5s ease, transform 0.5s ease;
	  border: 0px;
	  @media (max-width: 10000px) {
		width: 180px;
		height: 180px;
	  }
	  @media (max-width: 2560px) {
		width: 9.375vw;
		height: 9.375vw;
	  }
  
	  @media (max-width: 991px) {
		width: 18vw;
		height: 18vw;
	  }
	  @media (max-width: 565px) {
		width: 36vw;
		height: 36vw;
	  }
  }
  
  .diamond-container a {
	display: block;
	text-decoration: none;
  }
  
  
  .diamond-container:hover {
	transform: scale(1.05);
  }
  
  .diamond-container img {
	width: 100%;
	max-width: 320px;
	height: auto;
	transform: rotate(45deg);
  
	@media (max-width: 991px) {
	  transform: none; 
	}
  }
  
  .partner-1 { background-color: #313131; }
  .partner-1:hover { background-color: #000000; }
  
  .partner-2 { background-color: #000000; }
  .partner-2:hover { background-color: #666666; }
  
  .partner-3 { background-color: #ffffff; }
  .partner-3:hover { background-color: #0000007c; }
  
  .partner-4 { background-color: #323232; }
  .partner-4:hover { background-color: #000000; }
  
  .partner-5 { background-color: #222222; }
  .partner-5:hover { background-color: #000000; }
  
  .partner-6 { background-color: #636363; }
  .partner-6:hover { background-color: #000000; }
  
  .partner-7 { background-color: #000000; }
  .partner-7:hover { background-color: #666666; }
  
  .partner-8 { background-color: #ffffff; }
  .partner-8:hover { background-color: #0000007c; }
  
  .partner-9 { background-color: #222222; }
  .partner-9:hover { background-color: #000000; }
  
  .partner-1 img { 
	width: 160px; 
	@media (max-width: 2560px) {
		width: 9.081vw;
	  }
	@media (max-width: 991px) {
		width: 14vw;
	} 
	@media (max-width: 556px) {
		width: 30vw;
	} 
}

.partner-2 img { 
	width: 130px; 
	@media (max-width: 2560px) {
		width: 8.576vw;
	}
	@media (max-width: 991px) {
		width: 16vw;
	} 
	@media (max-width: 556px) {
		width: 32vw;
	} 
}

.partner-3 img { 
	width: 200px; 
	@media (max-width: 2560px) {
		width: 10.576vw;
	  }
	@media (max-width: 991px) {
		width: 20vw;
	}
	@media (max-width: 556px) {
		width: 32vw;
	} 
}

.partner-4 img { 
	width: 155px; 
	@media (max-width: 2560px) {
		width: 8.333vw;
	  }
	@media (max-width: 991px) {
		width: 16vw;
	}
	@media (max-width: 556px) {
		width: 30vw;
	} 
}

.partner-5 img { 
	width: 190px; 
	@media (max-width: 2560px) {
		width: 10.606vw;
	  }
	@media (max-width: 991px) {
		width: 15vw;
	}
	@media (max-width: 556px) {
		width: 30vw;
	} 
}

.partner-6 img { 
	width: 190px; 
	@media (max-width: 2560px) {
		width: 10.606vw;
	}
	@media (max-width: 991px) {
		width: 17vw;
	}
	@media (max-width: 556px) {
		width: 32vw;
	}
}

.partner-7 img { 
	width: 200px; 
	@media (max-width: 2560px) {
		width: 10.576vw;
	}
	
	@media (max-width: 991px) {
		width: 18vw;
	}
	@media (max-width: 556px) {
		width: 32vw;
	}
}

.partner-8 img { 
	width: 120px; 
	@media (max-width: 2560px) {
		width: 7.071vw;
	}
	@media (max-width: 991px) {
		width: 14vw;
	}
	@media (max-width: 556px) {
		width: 26vw;
	}
}

.partner-9 img { 
	width: 100px; 
	@media (max-width: 2560px) {
		width: 6.061vw;
	}
	
	@media (max-width: 991px) {
		width: 13vw;
	}
	@media (max-width: 556px) {
		width: 23vw;
	}
}
 