/*===========================
--. Footer Style CSS
===========================*/
.footer-area,
.call-top-action-wrap {
  background-color: $offWhite;
}

.widget-item {


  .widget-title {
    color: #666666;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    margin-top: -1px;

    @media #{$sm-device} {
      margin-bottom: 15px;
    }
  }

  address {
    line-height: 32px;
    margin-bottom: 0;
    font-weight: 400;
  }

  footer-address {
    font-size: 18px;
    line-height: 32px;
    margin-top: 20px;
    font-weight: 500;
    @media (max-width: 1600px) {
      font-size: 0.938vw;
    }
    @media (max-width: 1400px) {
      font-size: 0.938vw;
    }
    @media (max-width: 1200px) {
  
    }
    @media (max-width: 1024px) {
  
    }
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
      margin-top: 30px;
    }
    @media (max-width: 576px) {
  
    }
    @media (max-width: 420px) {
    
    }
  }
}

.widget-list {
  li {
    line-height: 32px;
    font-size: 18px;

    @media (max-width: 1600px) {
      font-size: 0.938vw;
    }
    @media (max-width: 1400px) {
      font-size: 0.938vw;
    }
    @media (max-width: 1200px) {
  
    }
    @media (max-width: 1024px) {
  
    }
    @media (max-width: 768px) {
  
    }
    @media (max-width: 576px) {
  
    }
    @media (max-width: 420px) {
    
    }

    a {
      color: $paragraph;
      font-weight: 500;
      transition: 0.2s;

      &:hover {
        color: $brandColor;
        padding-left: 5px;
      }
    }
  }
}

// About Widget
.about-widget {
  img {
    max-width: 220px;
    
    

    @media (max-width: 10000px) {
      width: 144px;
      height: 100%;
      margin-bottom: 20px;
    }
    @media (max-width: 1980px) {
      width: 7.5vw;
      height: 100%;
      margin-bottom: 1.042vw;
    }
    @media (max-width: 991px) {
      max-width: 144px;
      width: 100%;
      margin-bottom: 40px;
    }
    
   

    
}
}

.about-widget {
  p {
    padding-right: 50px;
  }
}

.copyright-txt {
  margin-top: 25px;
  font-weight: 500;

  @media #{$sm-device} {
    margin-top: 15px;
  }
}

.widget-body a:hover {
  color: #D9D9D9;
  font-weight: 600;
  transition: 0.4s;
  outline: none;
}

.footer-area {
  padding-top: 140px;
  @media #{$sm-device} {
    background-position: right bottom !important;
  }

}
.footer-text-centered{
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 32px;
  margin-top: -50px;
  font-size: 24px;
 
  @media (max-width: 1600px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1400px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 991px) {
    display: block;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    margin-top: 20px;
  }
  @media (max-width: 576px) {

  }
  @media (max-width: 420px) {
  
  }
}
.footer-copyright{
  color: #000000;
  font-weight: 500;


  @media (max-width: 10000px) {
    font-size: 18px;
  }
  @media (max-width: 1600px) {
    font-size: 0.938vw;
  }
  @media (max-width: 1400px) {
    font-size: 0.938vw;
  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 991px) {
    font-size: 16px;
  }
}