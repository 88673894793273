/*==========================================
28. Contact Page Style CSS
===========================================*/


.contact-us-h1{
  
  @media (max-width: 10000px) {
    font-size: 64px;
  }
  @media (max-width: 1980px) {
    font-size: 3.33333vw;
    
  }
  @media (max-width: 1600px) {
    font-size: 3.33333vw;
    
  }
  @media (max-width: 1400px) {
    font-size: 3.33333vw;
    
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 991px) {
    font-size: 46px;
    margin-bottom: 30px;
  }
}

.contact-form-wrap{
  
  @media (max-width: 10000px) {
    margin-top: -36.5px;
  }
  @media (max-width: 1980px) {
    margin-top: -50px;
  }
  @media (max-width: 1880px) {
    margin-top: -50px;
  }
  @media (max-width: 1600px) {
    margin-top: -1.901vw;
  }
  @media (max-width: 1440px) {
    margin-top: -32px;
  }

}

.single-input-item {
  color: $paragraph;
  margin-bottom: 30px;

  label,
  .custom-control-label {
    color: $paragraph;
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    position: relative;

    &.required {
      &:after {
        content: '*';
        color: red;
        margin-left: 2px;
      }
    }
  }

  input,
  textarea {
    border: 1px solid $borderColor;
    color: $paragraph;
    font-weight: 500;
    display: block;
    outline: none;
    padding: 15px 20px;
    transition: 0.3s;
    width: 100%;

    &:focus {
      border-color: $brandColorRed;
    }
  }

  .nice-select {
    line-height: 30px;
    height: 50px;
    width: 100%;

    .current {
      color: #8d8d8d;
      font-weight: 400;
    }

    .list {
      font-size: 14px;
    }
  }
}

.contact-method.contact-information{
  
  @media (max-width: 10000px) {
    height: 579px;
  }
  @media (max-width: 1980px) {
    height: 30.156vw;
  }
  @media (max-width: 1600px) {
    height: 30.156vw;
  }
  @media (max-width: 1440px) {
    height: 30.156vw;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }

}

.contact-method {


  &.contact-information {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    color: $white;
    display: flex;
    align-items: center;

    @media (max-width: 10000px) {
      padding: 45px 30px;
    }
    @media (max-width: 1980px) {
      padding-left: 2.344vw;
      padding-right: 2.344vw;
    }
    @media (max-width: 1880px) {
      padding-left: 2.344vw;
      padding-right: 2.344vw;
    }
    @media (max-width: 1600px) {
      padding-left: 2.344vw;
      padding-right: 2.344vw;
    }
    @media (max-width: 1440px) {
      padding-left: 2.344vw;
      padding-right: 2.344vw;
    }
    @media (max-width: 1200px) {
      
    }
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      padding: 50px 20px;
    }
    @media (max-width: 480px) {
      padding: 50px 20px;
    }

    h2 {
      color: $white;
      font-size: 1.875rem;
      margin-bottom: 45px;
    }

    h3 {
      color: $white;
      font-size: 1.875rem;
      margin-bottom: 45px;
    }

    .member-social-icons {

      
      a {
        height: auto;
        width: auto;
        margin-right: 15px;
        opacity: 1;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.contact-content-wrap {
  box-shadow: 0 10px 60px 0 rgba(71, 74, 182, 0.12);
}

.single-input-item-2 {
  color: $paragraph;
  font-weight: 300;
  
  @media (max-width: 10000px) {
    font-size: 24px;
  }
  @media (max-width: 1980px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1600px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1440px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1200px) {
    font-size: 1.25vw;
  }
  @media (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
 

  label,
  .custom-control-label {
    display: block;
    
    font-weight: 500;
    position: relative;

    @media (max-width: 10000px) {
      min-height: 40px;
    }
    @media (max-width: 1980px) {
      min-height: 2.5vw;
    }
    @media (max-width: 1600px) {
      min-height: 2.2vw;
    }
    @media (max-width: 1400px) {
      min-height: 3vw;
    }
    @media (max-width: 1200px) {
      
    }
    @media (max-width: 991px) {
      min-height: 40px;
    }
    @media (max-width: 768px) {
      min-height: 32px;
    }
    &.required {
      &:after {
        content: '*';
        color: red;
        margin-left: 2px;
      }
    }
  }

  input{
    border: 1px solid $borderColor;
    border-radius: 50px;
    border: 2px solid transparent;
    outline: none;
    color: rgba(236, 239, 246, 1);
    font-weight: 300;
    display: block;
    padding-left: 50px;
    transition: 0.3s;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: border-color 0.5s ease;

    @media (max-width: 10000px) {
      padding: 23px 20px;
      padding-left: 2.604vw;
    }
    @media (max-width: 1980px) {
      padding: 1.198vw 1.042vw;
      padding-left: 2.604vw;
    }
    @media (max-width: 1600px) {
      padding: 1vw;
      padding-left: 2.604vw;
    }
    @media (max-width: 1440px) {
      padding: 1vw;
      padding-left: 2.604vw;
    }
    @media (max-width: 1200px) {
      
    }
    @media (max-width: 991px) {
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: 762px) {
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:focus {
      border-color: $brandColorRed;
    }
  }

  .nice-select {
    line-height: 30px;
    height: 50px;
    width: 100%;

    .current {
      color: #8d8d8d;
      font-weight: 400;
    }

    .list {
      font-size: 14px;
    }
  }
}

.col-lg-8-contact-us-form {
  z-index: 0;
  flex: 0 0 auto;
  width: 73%;


  @media (max-width: 991px) {
    width: 100%;
    margin-top: 30px;
  }
  


}
.col-lg-4-contact-first-box{
  flex: 0 0 auto;
  width: 27%;

  @media (max-width: 991px) {
    width: 100%;

  }
}
.col-lg-4-contact-first-box img{
 
  @media (max-width: 10000px) {
    width: 193.02px;
    margin-left: -40px;
  }
  @media (max-width: 1980px) {
    width: 10.053vw;
  }
  @media (max-width: 1600px) {
    width: 10.053vw;
  }
  @media (max-width: 1400px) {
    width: 10.053vw;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
   margin-left: -20px;
   width: 144px;
  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 420px) {
  
  }

  
}
.col-lg-4-contact-first-box p{
 
  @media (max-width: 10000px) {
   
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 1980px) {
    font-size: 1.25vw;
    line-height: 1.771vw;
  }
  @media (max-width: 1600px) {
    font-size: 1.25vw;
    line-height: 1.771vw;
  }
  @media (max-width: 1400px) {
    font-size: 1.25vw;
    line-height: 1.771vw;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 480px) {
  
  }
  @media (max-width: 420px) {
  
  }

  
}




.contact-info-con a {
  color: #ffffff;
}

.contact-info-con a:hover {
  color: #d9d9d9;
}

.submit-button-container {
  display: flex;
  justify-content: flex-end;
}


/* Stilovi za inpute */
input:hover,
textarea:hover,
input:focus,
textarea:focus {
  border-color: #E12426;
}

input::placeholder,
textarea::placeholder {
  color: rgba(236, 239, 246, 0.3);
}

textarea {
  resize: none;
  overflow-y: auto;
  background-color: transparent;
}

.textarea-container {
  position: relative;
  width: 100%;
  max-width: 100%; 
  margin: 0 auto; 
  border: 2px solid transparent; 
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  transition: border-color 0.3s ease;

  @media (max-width: 10000px) {
    padding: 40px;
    height: 331px;
  }
  @media (max-width: 1980px) {
    padding: 2.083vw;
    height: 16.7vw;
  }


  @media (max-width: 1600px) {
    padding: 2.083vw;
    height: 17.24vw;
  }
  @media (max-width: 1440px) {
    padding-top: 1vw;
    padding-left: 2vw;
    height: 16.7vw;
  }
  @media (max-width: 1400px) {
    padding-top: 1vw;
    padding-left: 2vw;
    height: 13.7vw;
  }
  @media (max-width: 1200px) {
   
  }
  @media (max-width: 991px) {
    height: 200px;
    padding-left: 10px;
    padding-top: 20px;
  }
  @media (max-width: 768px) {
   height: 180px;
   padding-left: 10px;
   padding-top: 20px;
  }
}

.styled-textarea {
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  color: #fff;
  resize: none;
  outline: none;
  overflow-y: scroll;
  box-sizing: border-box;
  line-height: 1.5;

  @media (max-width: 10000px) {
    font-size: 24px;
    line-height: 43px;
    padding: 12px;
    height: 190px;
  }
  @media (max-width: 1980px) {
    font-size: 1.25vw;
    line-height: 2.24vw;
    height: 205px;
  }
  @media (max-width: 1880px) {
    font-size: 1.25vw;
    line-height: 2.24vw;
    height: 190px;
  }
  @media (max-width: 1600px) {
    font-size: 1.25vw;
    line-height: 2.24vw;
    height: 205px;
  }
  @media (max-width: 1440px) {
    font-size: 1.25vw;
    line-height: 2.24vw;
    height: 180px;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 20px;
    height: 135px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 2.24vw;
    height: 122px;
    line-height: 22px;
  }
}

/* Stilovi za scrollbar */
.styled-textarea::-webkit-scrollbar {
  width: 8px;
}

.styled-textarea::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

.styled-textarea:focus {
  outline: none;
}

.textarea-container:focus-within {
  border-color: $brandColorRed;
}

/* Stilovi za autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 50px 50px rgba(0, 0, 0, 0.3);
    border-color: #E12426;
}