/*============================
03. Common Style CSS
==============================*/
/*-----------------
Overflow
-------------------*/
.fix {
  overflow: hidden;

  &-x {
    overflow-x: hidden;
  }

  &-y {
    overflow-y: hidden;
  }
}


/*-----------------
Section Title
-------------------*/
.section-title {
  margin-bottom: 55px;

  @media #{$sm-device} {
    margin-bottom: 40px;
  }

  h3 {
    margin-bottom: 13px;
    font-weight: 400;
    text-transform: uppercase !important;
    font-size: 16px;
  }

  h6 {
    margin-bottom: 13px;
    font-weight: 400;
    text-transform: uppercase;
  }

  h2 {
    @media #{$sm-device} {
      font-size: 24px;
    }
  }

  &--light {
    color: #bbbbbb;

    h2,
    h3,
    h6 {
      color: $white;
    }
  }
}

/*----------------------
Background Image
-----------------------*/
.bg-img {
  background: no-repeat center center;
  background-size: cover;
}


/*----------------------
Container
-----------------------*/

.container-header {
    @media screen and (max-width: 1920px) and (min-width: 450px) {
    max-width: 100%;
    margin-left: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container {
  
  @media (max-width: 10000px) {
    --bs-gutter-x: 0vw;
    width: 100%;
    max-width: 1600px;

  }
  @media (max-width: 2560px) {
    --bs-gutter-x: 0vw;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 1800px) {
    --bs-gutter-x: 6.7vw;
    width: min(90vw, 1600px);
    max-width: min(90vw, 1600px);
  }
  @media (max-width: 1600px) {
    --bs-gutter-x: 6.7vw;
    width: min(90vw, 1600px);
    max-width: min(90vw, 1600px);
  }
  @media (max-width: 1280px) {
    --bs-gutter-x: 6.7vw;
  }
  @media (max-width: 1024px) {
    --bs-gutter-x: 6.7vw;
  }
  @media (max-width: 768px) {
    --bs-gutter-x: 0vw;
  }
  @media (max-width: 576px) {
    --bs-gutter-x: 0vw;
  }
  @media (max-width: 420px) {
    --bs-gutter-x: 0vw;
  }

  &-fluid {
    padding: 0 100px;

    @media #{$lg-device} {
      padding: 0 15px;
    }

    @media #{$md-device, $sm-device} {
      padding: 0 30px;
    }

    @media #{$xxs-device} {
      padding: 0 15px;
    }
  }
}
.our-story-container{
  @media (max-width: 10000px) {
    padding-top: 250px;
    padding-bottom: 250px;
    
  }
  @media (max-width: 2560px) {
    padding-top: 13.021vw;
    padding-bottom: 13.021vw;
  }
  @media (max-width: 1800px) {
    padding-top: 13.021vw;
    padding-bottom: 13.021vw;
  }
  @media (max-width: 1600px) {
    padding-top: 13.021vw;
    padding-bottom: 13.021vw;
  }
  @media (max-width: 1280px) {
   
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 768px) {
   
  }
  @media (max-width: 576px) {
   
  }
  @media (max-width: 420px) {
    
  }

}

.chevron-wrapper{
  

  @media (max-width: 10000px) {
  position: absolute;
  right: 0;
  top: 200px;
  width: 20vw;
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
    
  }
  @media (max-width: 2560px) {
    top: 270px;
    
  }
  @media (max-width: 1800px) {
    top: 270px;
   
  }
  @media (max-width: 1600px) {
    top: 220px;
    
  }
  @media (max-width: 1440px) {
    top: 220px;
  
  }
  @media (max-width: 1280px) {
    top: 170px;
  }
  @media (max-width: 1024px) {
    
  
  }
  @media (max-width: 991px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 576px) {
   
  }
  @media (max-width: 420px) {
    
  }
}

/*----------------------
Button Style
-----------------------*/
.hero-description-content {
  margin-bottom: 20px !important;
  color: #E42326 !important;
  text-transform: uppercase;
}

.hero-description-content-2 {
  margin-bottom: 20px !important;
  color: #ffffff !important;
}

.btn-transparent-white {
  background-color: transparent !important;
  border-color: #ffffff !important;
  border: 2px solid !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: uppercase;
  padding: 16px 20px !important;
  text-align: center;
}

.btn-transparent-white:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #151515 !important;
}

.btn-navigation-menu {
  background-color: #262626;
  color: $white;
  
  font-weight: 400;
  font-family: Montserrat;
  

  @media (max-width: 10000px) {
    font-size: 18px;
    padding: 12px 20px;
    width: 135px;
  }
  @media (max-width: 1980px) {
    font-size: 0.938vw;
    padding: 0.625vw 1.042vw;
    width: 7.031vw;
  }
  @media (max-width: 1600px) {
    font-size: 0.938vw;
    padding: 0.625vw 1.042vw;
    width: 7.031vw;
  }
  @media (max-width: 1400px) {
    font-size: 0.938vw;
    padding: 0.625vw 1.042vw;
    width: 7.031vw;
  }
  @media (max-width: 1200px) {
    font-size: 0.938vw;
    padding: 0.625vw 1.042vw;
    width: 7.031vw;
  }

}


.btn-navigation-menu {
  @media #{$md-device, $sm-device} {
    display: none;
  }

  @media #{$xxs-device} {
    dsplay: none;
  }
}

// Burger menu 
.btn-menu {
  color: #E12426 !important;
  background-color: transparent;
}


.btn-hero-explore {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 100px;
  color: #151515;
  padding: 16px 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  justify-content: space-between;
}

.btn-hero-explore:hover {
  background-color: transparent !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.btn-hero {
  background-color: transparent !important;
  border-color: #ffffff;
  border-radius: 100px;
  color: #FFFFFF;
  padding: 16px 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  justify-content: space-between;
}

.btn-hero:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #151515 !important;

}

.btn-hero-div {
  display: inline-flex;
}


.btn-about {
  background-color: #E42326;
  border-color: #E42326 !important;
  border-radius: 100px;
  border: 1px solid;
  color: #ffffff !important;
  padding: 16px 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  justify-content: space-between;
}

.btn-about:hover {
  background-color: transparent !important;
  border-color: #E42326 !important;
  color: #E42326 !important;
  border: 1px solid;
}


.arrow-icon {
  font-size: 18px;
  margin-bottom: 2px;
}

.linkedin-ico-contact {
  font-size: 30px;
  margin-left: -3px;
}
.linkedin-ico-footer {
  font-size: 30px;
  color: #ffffff;
}


// Home Hero Section Content

.col-xl-7_5 {
  width: 64%;
}

.col-xl-4_5 {
  width: 36%;
}

.col-xl-7_5 {
  @media #{$sm-device, $md-device} {
    width: 100%;
  }
}

.col-xl-7_5 {
  @media #{$lg-device} {
    width: 85%;
  }
}

.col-xl-7_5 {
  @media #{$xl-device} {
    width: 75%;
  }
}


.btn {
  &-brand {
    background-color: $brandColor;
    border-radius: 100px;
    color: $white;
    padding: 16px 40px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      color: $white;
    }

    @media #{$sm-device} {
      padding: 13px 25px;
    }
  }

  &-outline {
    border-radius: 50px;
    color: $paragraph;
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    margin-top: 30px;
  
    @media (max-width: 10000px) {
      font-size: 24px;
      padding: 20.5px 30px;
      width: 239px;
    }
    @media (max-width: 1980px) {
      font-size: 1.25vw;
      padding: 1.068vw 1.563vw;
      width: 12.448vw;
    }
    @media (max-width: 1600px) {
      font-size: 1.25vw;
      padding: 1.068vw 1.563vw;
      width: 12.448vw;
    }
    @media (max-width: 1400px) {
      font-size: 1.25vw;
      padding: 1.068vw 1.563vw;
      width: 12.448vw;
    }
    @media (max-width: 1200px) {
      
    }
    @media (max-width: 991px) {
      font-size: 18px;
      padding: 17px 10px;
      width: 31.12vw;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      padding: 15px 10px;
      width: 31.12vw;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      padding: 15px 10px;
      width: 31.12vw;
    }
    @media (max-width: 442px) {
      font-size: 16px;
      padding: 15px 10px;
      width: 180px;
    }

    &:hover {
      background: linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%);
      color: $white;
    }
  }
}


/*----------------------
Slick Index
-----------------------*/
.slick-list {
  .slick-slide {
    border: 0 solid transparent !important;

    &>div {
      &>div {
        vertical-align: middle;
        outline: none;
      }
    }
  }
}

.slick-dots {
  display: flex;
  margin-top: 35px;
  justify-content: center;
  height: 12px;

  @media #{$lg-device, $xl-device} {
    justify-content: flex-start;
  }

  li {
    margin-right: 10px;

    button {
      border: 2px solid $brandColor;
      border-radius: 50%;
      text-indent: -100000px;
      height: 12px;
      width: 12px;
    }

    &.slick-active {
      button {
        background-color: $brandColor;
      }
    }
  }

  &--light {
    li {
      button {
        border-color: $white;
      }

      &.slick-active {
        button {
          background-color: $white;
        }
      }
    }
  }
}

@for $i from 1 through 6 {
  .slick-row-#{5 * $i} {
    .slick-list {
      margin: 0 #{-2.5px * $i};

      .slick-slide {
        margin: 0 #{2.5px * $i};
      }
    }
  }
}


/*--------------------------------
Scroll Top Button
----------------------------------*/
.btn-scroll-top {
  background-color: $brandColor;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
  color: $white;
  cursor: pointer;
  @include font-size(25px);
  display: block;
  text-align: center;
  line-height: 60px;
  position: fixed;
  bottom: -60px;
  right: 30px;
  height: 60px;
  width: 60px;
  z-index: 999;
  @include hide;

  @media #{$sm-device} {
    line-height: 50px;
    height: 50px;
    width: 50px;
  }

  &:hover {
    background-color: $hvrColor;
  }

  &.show {
    bottom: 30px;
    @include show;
  }
}


/*--------------------------------
Blockquote Style
----------------------------------*/
blockquote,
.blockquote {
  background-color: $offWhite;
  color: $paragraph;
  @include font-size(20px);
  line-height: 1.4;
  padding: 42px 44px 80px;
  position: relative;
  margin-bottom: 20px;


  &:before {
    content: '\f10e';
    font-family: $fa-icon;
    position: absolute;
    right: 40px;
    bottom: 40px;

    @media #{$sm-device} {
      right: 30px;
      bottom: 20px;
    }
  }

  &-title {
    color: $brandColor;
    text-transform: uppercase;
    @include font-size(14px);
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 1;
  }
}

// Heading Divider Image 

.divider-image {
  max-width: 120px;
}

/*--------------------------------
Footer divs order small devices
----------------------------------*/
.order-4 {
  order: 0 !important;
}

.order-0-services {
  @media #{$sm-device} {
    order: 1 !important;
  }
}

.order-1-services {
  @media #{$sm-device} {
    order: 1 !important;
  }
}

/*--------------------------------
Error 404 styles
----------------------------------*/
.error_404_div {
  margin-top: -100px !important;
}

.error_404_div img {
  @media #{$sm-device} {
    width: auto;
  }
}

/*--------------------------------
Why work with us styles
----------------------------------*/

.why-work-with-us-ico {
  margin-top: 20px;

  @media (max-width: 10000px) {
    width: 225.56px;
  }
  @media (max-width: 1745px) {
    width: 9.417vw;
    margin-top: 1.01vw;
  }
  @media (max-width: 1536px) {
    width: 9.417vw;
    margin-top: 1.01vw;
  }
  @media (max-width: 1440px) {
    width: 9.417vw;
  }
  @media (max-width: 1200px) {
    width: 9.417vw;
  }
  @media (max-width: 980px) {
    width: 140px;
  }
  @media (max-width: 768px) {
    width: 100px;
  }
}

.core-domain-of-expertise-h2{
 
  @media (max-width: 10000px) {
    font-size: 2.813vw;
    padding-top: 20px;
    padding-bottom: 55px;
  }
  @media (max-width: 1600px) {
    font-size: 2.813vw;
    padding-top: 60px;
    padding-bottom: 55px;
  }
  @media (max-width: 1400px) {
    font-size: 2.813vw;
  }
  @media (max-width: 1200px) {
    font-size: 2.813vw;
  }
  @media (max-width: 1024px) {
    font-size: 2.813vw;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    padding-top: 10px;
    padding-bottom: 55px;
  }
  @media (max-width: 768px) {
    font-size: 40px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  @media (max-width: 576px) {
    font-size: 40px;
  }
}
.core-domain-of-expertise-paragraph {
  font-size: 20px;
 
  @media (max-width: 1600px) {
    font-size: 1.146vw;
  }
  @media (max-width: 1400px) {
    font-size: 1.146vw;
  }
  @media (max-width: 1200px) {
    font-size: 1.146vw;
  }
  @media (max-width: 1024px) {
    font-size: 1.146vw;
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    
  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 420px) {
  
  }
}
.core-domain-of-expertise-row{
  width: 100%;
  line-height: 32px;
  grid-template-columns: repeat(3, 1fr);
  display: inline-grid;
  gap: 35px; 
  padding-bottom: 35px;
  
  
  @media (max-width: 1024px) {
    width: 100%;
    line-height: 32px;
    grid-template-columns: repeat(2, 1fr);
    display: inline-grid;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    line-height: 32px;
    grid-template-columns: repeat(1, 1fr);
    display: inline-grid;
  }
  
}

.core-domain-of-expertise-main-container{
  height: auto;
}

.core-domain-of-expertise-ico{

  @media (max-width: 2560px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 1980px) {
    width: 4.167vw;
    height: 4.167vw;
  }
  @media (max-width: 1800px) {
    width: 4.167vw;
    height: 4.167vw;
  }
  @media (max-width: 1600px) {
    width: 4.167vw;
    height: 4.167vw;
  }
  @media (max-width: 1400px) {
    width: 4.167vw;
    height: 4.167vw;
  }
  @media (max-width: 1200px) {
    width: 4.167vw;
    height: 4.167vw;
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 452px) {
    
  }
}

.col-md-6-core-domain{
  @media #{$sm-device, $md-device} {
    width: 100%;
  }
}

.core-domain-of-expertise-box{

  @media (max-width: 10000px) {
    padding: 50px 35px 50px 35px;
  }
  @media (max-width: 2560px) {
    
  }
  @media (max-width: 1980px) {
    
  }
  
  @media (max-width: 1600px) {
    
  }
  @media (max-width: 1400px) {
    
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
    padding: 50px 25px 40px 25px;
    min-height: 350px;
  }
  @media (max-width: 768px) {
    padding: 50px 20px 40px 20px;
    min-height: 350px;
  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 452px) {
    
  }
}
.code-me-up-market-presence-globe{
  @media #{$sm-device, $md-device} {
    
  }



  @media (max-width: 10000px) {
    margin-top: 50px;
  }
  
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 980px) {
    margin-top: 0px;
  }
  @media (max-width: 768px) {
  
  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 452px) {
  
  }
}

.transform-your-digital-vision-with-us-paragraph {


  @media (max-width: 10000px) {
    font-size: 24px;
    max-width: 675px;
    line-height: 32px;
  }
  @media (max-width: 1980px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1800px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1600px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1200px) {
    font-size: 1.25vw;
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
}
.transform-your-digital-vision-growth-container{
    

  @media (max-width: 10000px) {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 40px;
    margin-top: 50px;
  }
  @media (max-width: 1980px) {
    
  }
  @media (max-width: 1600px) {
    
  }
  @media (max-width: 1400px) {
    
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    padding-bottom: 20px;
    display: block;
  }
}
.transform-your-digital-vision-elements{
  
  p{
    @media (max-width: 10000px) {
      line-height: 32px;
      font-size: 22px;
      width: 257px;
    }
    @media (max-width: 1600px) {
      font-size: 1.146vw;
      width: 200px;
      line-height: 1.7vw;
    }
    @media (max-width: 1200px) {
      font-size: 1.146vw;
      width: 200px;
      line-height: 24px;
    }
    @media (max-width: 991px) {
      font-size: 20px;
      width: 260px;
      line-height: 22px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
      width: 260px;
      line-height: 22px;
    }
  }
  @media (max-width: 10000px) {
    width: 357px;
    padding: 35px;
  }
  @media (max-width: 1600px) {
    width: 22vw;
    padding: 1.823vw;
  }

  @media (max-width: 1200px) {
    width: 22vw;
    padding: 1.823vw;
  }
  @media (max-width: 991px) {
    width: 38vw;
    padding: 2.823vw;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;
  }
  @media (max-width: 550px) {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;
  }

}

/*--------------------------------
 What we do circular icons
----------------------------------*/
.feature-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1980px) {
    width: 13.385vw;
    height: 13.385vw;
  }

  @media (max-width: 1024px) {
    width: clamp(120px, 14vw, 180px);
    height: clamp(120px, 14vw, 180px);
  }

  @media (max-width: 768px) {
    width: clamp(120px, 15vw, 120px);
    height: clamp(120px, 15vw, 120px);
    margin: -5px;
  }
 
}
.feature-item-hoverable {
  border-radius: 50%;
  background: linear-gradient(180deg, #E12426 0%, #C61F20 69%, #B41214 100%);
  padding: 7px;

  @media (max-width: 980px) {
    padding: 5px;
  }
  @media (max-width: 768px) {
    padding: 4px;
  }
  @media (max-width: 520px) {
    padding: 3px;
  }
}
.what-we-do-wrapper{
  @media (max-width: 10000px) {
    height: 920px;
  }
  @media (max-width: 1980px) {
    height: 47.917vw;
  }

  @media (max-width: 1600px) {
    height: 47.917vw;
  }
  @media (max-width: 1400px) {
    height: 47.917vw;
  }
  @media (max-width: 1200px) {
    height: 47.917vw;
  }
  @media (max-width: 1024px) {
    height: 54.917vw;
  }
  @media (max-width: 980px) {
    height: 500px;
  }
  @media (max-width: 768px) {
    height: 870px;
  }
  
}



.what-we-do-item-image {
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}
.what-we-do-item-chevron-ico{
  width: 3.232vw;


  @media (max-width: 768px) {
    width: 26px;
    transform: rotate(90deg);
    margin-top: 24px;
  }


}
.feature-item-hoverable:hover .what-we-do-item-image {
  transform: scale(1.05);
}
.what-we-do-item-title {
  position: absolute;
  
  text-align: center;
  color: $lightGrayishBlue;
  pointer-events: none;

  @media (max-width: 10000px) {
    font-size: 32px;
    width: 320px;
    margin-top: 350px;
  }
  @media (max-width: 2560px) {
    font-size: 1.667vw;
    width: 16.667vw;
  }
  @media (max-width: 1980px) {
    font-size: 1.667vw;
    width: 16.667vw;
  }
  @media (max-width: 1440px) {
    font-size: 1.667vw;
    width: 16.667vw;
  }
  @media (max-width: 1280px) {
    font-size: 1.667vw;
    width: 16.667vw;
    margin-top: 215px;
  }
  @media (max-width: 980px) {
    font-size: 22px;
    width: 225px;
    margin-top: 200px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 200px;
    margin-top: 170px;
  }

  
}
.what-we-do-container{


@media (max-width: 10000px) {
  max-width: 67.08vw;
  --bs-gutter-x: 20vw;
}
@media (max-width: 2560px) {
  max-width: 82vw;
  --bs-gutter-x: 20vw;
}
@media (max-width: 1980px) {
  max-width: 67.08vw;
  --bs-gutter-x: 0vw;
}
@media (max-width: 1280px) {
  max-width: 67.08vw;
  --bs-gutter-x: 0vw;
}
@media (max-width: 1024px) {
  max-width: 70vw;
  --bs-gutter-x: 0vw;
}
@media (max-width: 980px) {
  max-width: 75vw;
  --bs-gutter-x: 0vw;
}
@media (max-width: 768px) {
  max-width: 75vw;
  --bs-gutter-x: 0vw;
}
@media (max-width: 636px) {
  max-width: 80vw;
  --bs-gutter-x: 0vw;
}
@media (max-width: 525px) {
  --bs-gutter-x: 0vw;
}
}




/*--------------------------------
 Market presence globe
----------------------------------*/

.market-presence-container p{
  font-size: 24px;
  margin-bottom: 50px;
  @media #{$sm-device, $md-device} {
    font-size: 18px;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
    font-size: 20px;

  }
  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 20px;

  }

}
.market-presence-container{
  @media #{$xl-device} {
    align-items: center;
    display: flex;
  }
  @media #{$md-device} {
    align-items: start;
    display: block;
  }
}



.image-container {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.image-container.visible {
  opacity: 1;
}


/*--------------------------------
 Why work with us fade in/out animation
----------------------------------*/

@keyframes fadeInLeft {
  from {
      opacity: 0;
      transform: translateX(-100px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
      opacity: 0;
      transform: translateX(100px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(100px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
      opacity: 0;
      transform: translateY(-100px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.animate {
  opacity: 0;
  transition: opacity 0.5s ease-out;

  @media (max-width: 10000px) {
    padding: 50px;
  }
  @media (max-width: 2560px) {
    padding: 2.604vw;
  }

  @media (max-width: 1600px) {
    padding: 35px;
  }

  @media (max-width: 1200px) {
    
  }

  @media (max-width: 1024px) {
   
  }

  @media (max-width: 768px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media (max-width: 576px) {
    
  }

  @media (max-width: 420px) {
    
  }

}

.animate.fade-in-left {
  animation: fadeInLeft 1s forwards;
}

.animate.fade-in-right {
  animation: fadeInRight 1s forwards;
}

.animate.fade-in-up {
  animation: fadeInUp 1s forwards;
}

.animate.fade-in-down {
  animation: fadeInDown 1s forwards;
}


