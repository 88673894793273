/*=====================================
   01. Typography CSS
===================================== */


body {
  background-color: #000000;
  color: $paragraph;
  // @include font-size($baseFontSize);
  font-size: 18px;
  font-family: $baseFont;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.44px;
  margin: 0; 
 
  @media (max-width: 1600px) {
    font-size: 0.938vw;
  }
  @media (max-width: 1400px) {
    font-size: 0.938vw;
  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 576px) {

  }
  @media (max-width: 420px) {
  
  }
}

/* Remove text-shadow in selection highlight. */
::selection {
  background: #ffffff0a;
  text-shadow: none;
}

/* A better looking default horizontal rule */
hr {
  border: 0;
  border-top: 1px solid $borderColor;
  box-sizing: content-box;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  overflow: visible;
}

/* Remove the gap between audio, canvas, iframes,images, videos */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/* Remove default fieldset styles. */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

/* Anchor Tag Default Style */
main-menu a:hover {
  color: black;
}

a {
  transition: 0.4s;
  outline: none;
  text-decoration: none;
  color: #d9d9d9;

  // &:hover,
  &:active,
  &:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
  }
}

textarea,
input {

  &:focus,
  &:active {
    color: inherit;
    outline: none;
    text-decoration: none;
  }
}

/* Heading Default Style */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 400;
  color: $headingColor;
  line-height: 1.2;
}

h1 {
  @include font-size(3.3333vw);
  max-width: 43.54vw;
  font-weight: 600;
  margin-bottom: 2vw;
  color: $lightGrayishBlue;


  
  @media (max-width: 1200px) {
    font-size: 32px;
    max-width: 43.54vw;
  }
  @media (max-width: 980px) {
    font-size: 32px;
    max-width: 43.54vw;
  }
  @media (max-width: 768px) {
    font-size: 46px;
    max-width: 100%;
  }
}

h2,
.h2 {
  @include font-size(54px);
  font-weight: 500;
  margin-top: -5px;
  margin-bottom: 18px;
  @media #{$sm-device, $md-device} {
    font-size: 44px;
  }
}

h3,
.h3 {
  // @include font-size(48px);
  font-size: clamp(24px, 5vw, 48px);
  font-weight: 500;
  margin-bottom: 10px;
}

h4,
.h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0px;

  @media (max-width: 1600px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1400px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1200px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1024px) {
    font-size: 1.25vw;
  }
  @media (max-width: 991px) {
    font-size: 2.2vw;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 576px) {

  }
  @media (max-width: 452px) {

  }
}

h5,
.h5 {
  @include font-size(20px);
}

h6,
.h6 {
  @include font-size(16px);
}

/* Heading custom styles */
.h-tag-call-to-action {
  font-weight: 600;
  color: #ffffff;
  line-height: 1.2;
}


.what-we-do-h2{
  font-size: clamp(44px, 2.8125vw, 54px);
  margin-top: -100px;
  
  @media #{$sm-device, $md-device} {
    font-size: 44px;
  }


  @media (max-width: 10000px) {
    margin-bottom: 100px;
  }
  @media (max-width: 1920px) {
    margin-bottom: 8.333vw;
  }
  @media (max-width: 1536px) {
    margin-bottom: 8.333vw;
  }

  @media (max-width: 1200px) {
    margin-top: -50px; 
  }

  @media (max-width: 1024px) {
    margin-top: -50px; 
  }
  @media (max-width: 991px) {
    font-size: 40px;
    margin-top: -50px; 
  }
  @media (max-width: 768px) {
    font-size: 40px;
    margin-top: -20px;
  }
}

.why-work-with-us-row{
  

  @media (max-width: 10000px) {
    row-gap: 60px;
  }
  @media (max-width: 1920px) {
    row-gap: 4.167vw;
  }
  @media (max-width: 1536px) {
    row-gap: 2.125vw;
  }

  @media (max-width: 1200px) {
    
  }

  @media (max-width: 1024px) {
   
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 576px) {
  }

  @media (max-width: 420px) {

  }
}

.why-work-with-us-h2{
  font-size: clamp(44px, 2.8125vw, 54px);

  @media (max-width: 10000px) {
    font-size: 54px;
  }
  @media (max-width: 1980px) {
    font-size: 2.5vw;
    
  }
  @media (max-width: 1600px) {
    font-size: 2.5vw;
  
  }
  @media (max-width: 1400px) {
    font-size: 2.5vw;
    
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
}

.transform-your-digital-vision-with-us-h2{
  width: 535px;
  display: block;
  font-size: clamp(40px, 2.5vw, 48px);
  @media #{$sm-device, $md-device} {
    font-size: 38px;
    width: auto;
  }


  @media (max-width: 10000px) {
    font-size: 2.5vw;
  }
  @media (max-width: 1980px) {
    
  }
  @media (max-width: 1600px) {
    
  }
  @media (max-width: 1400px) {
    
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {

  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 452px) {
  
  }
}
.transform-your-digital-vision-elements-h3{

  @media (max-width: 10000px) {
    font-size: 48px;
  }
  @media (max-width: 1600px) {
    font-size: 2.5vw;
  }

  @media (max-width: 1200px) {
    font-size: 2.5vw;
  }

  @media (max-width: 1024px) {
    font-size: 2.5vw;
  }
  @media (max-width: 991px) {
    font-size: 32px;
  }
  @media (max-width: 768px) {
  }

  @media (max-width: 576px) {
  }

  @media (max-width: 420px) {

  }

}


/* Paragraph Margin */
p {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Paragraph Margin */
.p-tag-call-to-action {
  color: #ffffff;
  font-weight: 600 !important;
}

/* Paragraph Margin */
.p-tag-call-to-action {
  color: #ffffff;
  font-weight: 600 !important;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin-bottom: 0px;
  }
}

strong,
b {
  font-weight: 500;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

button {
  background-color: #313131;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  box-shadow: none;
  transition: 0.4s;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

[data-mfp-src],
.btn-img-popup {
  cursor: zoom-in;
}

.parallax {
  background-repeat: no-repeat;
  background-size: cover;
}

.form-message.alert {
  margin-bottom: 0;
  margin-top: 10px;
  position: absolute;
  width: 100%;
}

// // Footer services paragraph
// .widget-list li {
//   margin-bottom: 10px;
//   font-size: 22x
  
// }

// Why work with us title
.h2-lg-device-why-work-with-cmu {
  @media #{$sm-device} {
    display: none;
  }

}

.h2-sm-device-why-work-with-cmu {
  @media #{$md-device, $lg-device, $xl-device} {
    display: none;
  }

}

.text-red-400 {
  font-weight: 300;
  color: #E12426;
  padding-left: 30px;
  
  
  

  @media (max-width: 10000px) {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: -10px;
    min-height: 1px;
  }
  @media (max-width: 1980px) {
    font-size: 1.042vw;
    margin-top: 0.43vw;
    margin-bottom: -0.421vw;
    min-height: 0.052vw;
  }
  @media (max-width: 1600px) {
    font-size: 1.042vw;
    margin-top: 0vw;
    margin-bottom: -0.121vw;
    min-height: 0.052vw;
  }
  @media (max-width: 1440px) {
    font-size: 1.042vw;
    margin-top: 0.4vw;
    margin-bottom: -0.421vw;
    min-height: 0.052vw;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.error-paragraph {
  color: #ffffff !important;
  font-weight: 600;
  font-size: 25px;
  margin-top: -50px;
}

.error-paragraph {
  @media #{$sm-device} {
    margin-top: -40px;
  }
}

.our-story-paragraph{
  color: #ffffff;
  z-index: 3;
  position: relative;

  @media (max-width: 10000px) {
    font-size: 24px;
    line-height: 36px;
    width: 1179px;
  }
  @media (max-width: 1980px) {
    font-size: 1.25vw;
    line-height: 1.875vw;
    width: 61.406vw;
  }
  @media (max-width: 1600px) {
    font-size: 1.25vw;
    line-height: 1.875vw;
    width: 61.406vw;
  }
  @media (max-width: 1400px) {
    font-size: 1.25vw;
    line-height: 1.875vw;
    width: 61.406vw;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }

}

.why-work-with-us-h4 {
  font-size: 28px;
  margin-bottom: 15px;
 
  @media (max-width: 1600px) {
    font-size: 1.458vw;
  }
  @media (max-width: 1400px) {
    font-size: 1.458vw;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 980px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {

  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 420px) {
  
  }
}
.why-work-with-us-paragraph {
  color: $lightGrayishBlue;
  font-size: 18px;
  line-height: 32px;
  opacity: 0.7;


  @media (max-width: 1600px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1400px) {
    font-size: 1.25vw;
  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }

}