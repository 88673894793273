/*==========================
3.0 - Template Spacing
============================*/

//Company Overview - Features row style
.services-features-row {
  --bs-gutter-y: 2rem;
}

.company-overview-row {
  --bs-gutter-y: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 30px;
  }


}

// Section Padding
.sp-top {

  @media #{$lg-device, $xl-device} {
    padding-top: 90px;
  }
  @media #{$md-device} {
    padding-top: 0px;
  }
  @media #{$sm-device} {
    padding-top: 60px;
  }
}

.sp-top-wt {
  padding-top: 105px;

  @media #{$md-device} {
    padding-top: 85px;
  }

  @media #{$sm-device} {
    padding-top: 57px;
  }
}

.sp-bottom {
  padding-bottom: 110px;

  @media #{$md-device} {
    padding-bottom: 90px;
  }

  @media #{$sm-device} {
    padding-bottom: 60px;
  }
}

.sp-bottom-footer {
  padding-bottom: 55px;

  @media #{$md-device} {
    padding-bottom: 90px;
  }

  @media #{$sm-device} {
    padding-bottom: 60px;
  }
}


.sp-bottom-wt {
  padding-bottom: 105px;

  @media #{$md-device} {
    padding-bottom: 85px;
  }

  @media #{$sm-device} {
    padding-bottom: 57px;
  }
}

.sp-y {
  padding: 90px 0;
  margin-top: -3px;
  margin-bottom: -3px;

  @media #{$md-device} {
    padding: 90px 0;
  }

  @media #{$sm-device} {
    padding: 60px 0;
  }
}

// Section Margin
.sm-top-services-page-features {
  margin-top: 40px;

  @media #{$md-device} {
    margin-top: 40px;
  }

  @media #{$sm-device} {
    margin-top: 40px;
  }
}

.sm-top {
  // margin-top: 104px;

  @media #{$md-device} {
    margin-top: 0px;
  }

  @media #{$sm-device} {
    margin-top: 0px;
  }
}

.contact-page-area-wrapper.sm-top{
  @media #{$md-device} {
    margin-top: 0px;
    padding-top: 160px;
  }
  @media #{$sm-device} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}



.sm-top-wt {
  // margin-top: 105px;

  @media #{$md-device} {
    margin-top: 0px;
  }

  @media #{$sm-device} {
    margin-top: 0px;
  }
}

.sm-y {
  margin: 110px 0;

  @media #{$md-device} {
    margin: 90px 0;
  }

  @media #{$sm-device} {
    margin: 60px 0;
  }
}

// Divs spacing
.heading-divider-image-div {
  margin-top: 0px;
  margin-bottom: 20px;
}

.divider-image-services {
  margin-top: -18px;
  width: 120px;
}

// About Us thumb images
.about-thumb-1 {
  border-radius: 15px;
  margin-right: 30px;
  max-width: 95%;
}

.icon-box-item {
  @media #{$sm-device} {
    margin-top: 0px !important;
  }
}

//about page spacing
.about-page-space {
  margin-bottom: 100px;
}
.about-page-space {
  @media #{$sm-device} {
    margin-bottom: 60px;
  }
}
.hero-area-wrapper{

  height: 100%;
}
.sm-top-x2 {

  margin-top: 110px;
}