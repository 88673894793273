/*==========================
04. Index Area Style CSS
===========================*/

.header-area img{
  max-width: 200px;
}
.logo-area img{
  
  
  @media (max-width: 10000px) {
    width: 144px;
    height: 100%;
  }
  @media (max-width: 1980px) {
    width: 7.5vw;
  }
  @media (max-width: 1600px) {
    width: 7.5vw;
  }
  @media (max-width: 1400px) {
    width: 7.5vw;
  }
  @media (max-width: 1200px) {
    width: 7.5vw;
  }
  @media (max-width: 991px) {
    max-width: 144px;
    width: 100%;
  }

}


.header-area.not-sticky .btn-navigation-menu{
  border-radius: 100px;
  color: $white;
  background: #FFFFFF1A;
}
.header-area.not-sticky .btn-navigation-menu:hover{
  background: linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%);
  color: #ffffff;
}
.header-area .not-sticky .btn-navigation-menu:hover{
  border-radius: 100px;
}

.header-area.sticky .btn-navigation-menu{
  background: #FFFFFF1A;
    border-radius: 30px;
}

.header-area.sticky .btn-navigation-menu:hover{
  background-color: #e42326;
    border-radius: 30px;
}


.slider-area.sm-top{

  @media (max-width: 992px) {
    margin-top: 0px;
  }
}



.header-area{
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  opacity: 1; 
  z-index: 999;
  margin-bottom: -100px;
  padding: 20px 40px;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  top: 0;
  
  @media (max-width: 768px) {
    padding: 20px 20px;
  }
  @media (max-width: 576px) {
  
  }
  @media (max-width: 420px) {
  
  }

  &.sticky{
    animation: slideInDown 0.6s forwards;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(150px);
    -webkit-backdrop-filter: blur(150px);

    @media #{$sm-device, $md-device} {
      padding: 20px 20px;
    }

    .logo{
      &-dark{
        display: none;
      }
    }


    .main-menu{
      .submenu-nav{
        margin-top: 20px;

        &:before{
          height: 25px;
        }
      }
    }
  }

  &.transparent:not(.sticky){
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .logo{
      &-light{
        display: none;
      }
    }

    .main-menu{
      & > li{

        &.has-submenu{
          &:after{
            color: $brandColor;
            font-weight: 600;
          }
        }

        & > a{
          color: $brandColor;
          font-weight: 600;
        }
      }
    }

    .header-action{
      a,button{
        color: $brandColor;
        font-weight: 600;
      }
    }
  }
}

// Index Action
.header-action{
  color: $white;
  font-size: 22px;

  @media #{$lg-device, $sm-device} {
    font-size: 20px;
  }

  a{
    color: $white;

    &.tel-no{
      @media #{$xs-device} {
        display: none;
      }
    }
  }

  [class*='btn-']{
    color: $white;
  }
}

.main-menu {
  @media #{$xs-device, $sm-device, $md-device} {
    display: none;
  }

}

.btn-navigation-menu {
  font-weight: normal;
  background: none;
  transition: all 0.3s ease;
}

.btn-navigation-menu.active {
  font-weight: 500;
  background: linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}