/*========================
09. Icon Box Style CSS
=========================*/

.icon-box-item-company-overview {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  transition: 0.4s;
}

.icon-box-item-company-overview:hover {
  background-color: #f8f9fc;
  // background-color: #ebesef7; 
  background-color: rgba(71, 75, 182, 0.079);
  box-shadow: 0 10px 80px 0 rgba(71, 74, 182, 0.16);
  transform: translateY(-10px);
  transition: 0.4s;
  border-radius: 20px;
}

.icon-box-item {
  display: flex;
  align-items: center;

  @media #{$md-device} {
    display: block;
    margin-top: 40px;
  }

  @media #{$sm-device} {
    margin-top: 40px;
    display: flex;

  }



  .icon-box {
    &__icon {
      text-align: center;
      margin-right: 10px;
      // flex-basis: 50px;
      width: 50px;

      @media #{$md-device} {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__info {
      flex-basis: calc(100% - 50px);
      width: calc(100% - 50px);

      @media #{$xl-device} {
        // flex-basis: 225px;
        // width: 215px;
        flex-basis: 300px;
        width: 300px;
      }

      @media #{$md-device} {
        flex-basis: 100%;
        width: 100%;
      }

      h4 {
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 10px;
      }

      h5 {
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 10px;
      }
    }
  }
}

.icon-box {
  &__icon {
    text-align: center;
    margin-right: 10px;
    // flex-basis: 50px;
    width: 50px;

    @media #{$md-device} {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__info-services {
    flex-basis: calc(100% - 50px);
    width: calc(100% - 50px);

    @media #{$xl-device} {
      // flex-basis: 225px;
      // width: 215px;
      flex-basis: 400px;
      width: 300px;
    }

    @media #{$md-device} {
      flex-basis: 100%;
      width: 100%;
    }

    @media #{$sm-device} {
      flex-basis: 100%;
      width: 100%;
      // padding-left: 10px;
      // padding-right: 10px;
      text-align: justify;
      text-align-last: center;
    }

    h3 {
      font-weight: 600;
      line-height: 1;
      margin-bottom: 10px;
      font-size: 1.25rem;
    }

    h4 {
      font-weight: 600;
      line-height: 1;
      margin-bottom: 10px;
    }

    h5 {
      font-weight: 600;
      line-height: 1;
      margin-bottom: 10px;
    }
  }
}




.about-icon-box-item {
  display: flex;
  align-items: center;

  @media #{$md-device} {
    display: block;
    margin-top: 40px;
    width: 50%;
  }

  @media #{$sm-device} {
    margin-top: 40px;
    display: flex;
    width: 50%;
  }

  .icon-box {
    &__icon {
      text-align: center;
      margin-right: 10px;
      // flex-basis: 50px;
      width: 50px;

      @media #{$md-device} {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__info {
      flex-basis: calc(100% - 50px);
      width: calc(100% - 50px);

      @media #{$xl-device} {
        // flex-basis: 225px;
        // width: 215px;
        flex-basis: 300px;
        width: 300px;
      }

      @media #{$md-device} {
        flex-basis: 100%;
        width: 100%;
      }

      h5 {
        font-weight: 500;
        line-height: 1;
        margin-bottom: 10px;
      }
    }
  }
}



.icon-box {
  &__icon-2 {
    text-align: center;
    margin-right: 20px;
    flex-basis: 50px;
    width: 50px;

    @media #{$md-device} {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__info {
    flex-basis: calc(100% - 50px);
    width: calc(100% - 50px);

    @media #{$xl-device} {
      // flex-basis: 225px;
      // width: 215px;
      flex-basis: 300px;
      width: 300px;
    }

    @media #{$md-device} {
      flex-basis: 100%;
      width: 100%;
    }

    h3 {
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 10px;
      font-size: 1.25rem;
    }

    h5 {
      font-weight: 500;
      line-height: 1;
      margin-bottom: 10px;
    }
  }
}

.icon-box {
  &__icon-2 {
    text-align: center;
    margin-right: 20px;
    min-width: 85px;

    @media #{$md-device} {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}


.icon-box {
  &__icon-box__icon-services {
    text-align: center;
    margin-right: 20px;
    min-width: 50px;

    @media #{$md-device} {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}