/*==========================
08. About Style CSS
==========================*/



.about-content {
  @media #{$xl-device} {
    // margin-left: 55px;
    // padding-right: 80px;
  }
  .animate-slide-up{
    padding-left: clamp(20px, 6%, 211px);
    padding-right: clamp(20px, 6%, 211px);
    line-height: 1.7;
    margin-top: 50px;
    @media #{$sm-device, $md-device, $lg-device} {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 20px;
    }
  }

  h6 {
    margin-bottom: 13px;
  }

  .about-since {
    color: #484848;
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    position: relative;
    padding-right: 20px;
    margin-bottom: 30px;

    @media #{$sm-device} {
      margin-bottom: 20px;
    }

    &:after {
      background-color: #b4b4b4;
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 110px;
    }
  }

  .btn-about {
    color: $paragraph;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    margin-top: 15px;

    @media #{$sm-device} {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    i {
      font-size: 20px;
      line-height: 3px;
      vertical-align: middle;
      transition: 0.3s;
    }

    &:hover {
      i {
        padding-left: 5px;
      }
    }
  }

  &--2 {
    @media #{$xl-device} {
      margin-left: 0;
      padding-right: 40px;
    }
  }
}


// Home 2 About Style
.home-two-about-area {
  background-color: $offWhite;
  //background-image: url('../img/about-2-bg.jpg');
  background-position: center right;
  background-repeat: no-repeat;

  @media #{$lg-device, $xlmax-device} {
    background-size: 1100px auto;
  }

  @media #{$sm-device, $md-device} {
    background-image: none !important;
  }

  .about-content {
    padding-top: 100px;
    padding-bottom: 110px;

    @media #{$md-device} {
      padding-top: 40px;
      padding-bottom: 90px;
    }

    @media #{$sm-device} {
      padding-bottom: 60px;
      padding-top: 0;
    }
  }
}

.video-button-thumb {
  position: relative;

  &:after {
    content: '';
    background-color: $brandColor;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .btn-play {
    border-radius: 50%;
    background-color: $white;
    color: $brandColor;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    &:hover {
      background-color: $brandColor;
      color: $white;
    }

    i {
      padding-left: 5px;
    }
  }
}

/* Second section text transition effect */
.heading{
  opacity: 0;
  transform: translateY(-300%);
  transition: all 1s ease-out;
}


.who-are-we-wrapper{
  
  @media (max-width: 10000px) {
    height: 920px;
  }
  @media (max-width: 1980px) {
    height: 47.917vw;
  }
  @media (max-width: 1600px) {
    height: 47.917vw;
  }
  @media (max-width: 1400px) {
    height: 47.917vw;
  }
  @media (max-width: 1200px) {
    height: 47.917vw;
  }
  @media (max-width: 1024px) {
    height: 47.917vw;
  }

  @media (max-width: 980px) {
    height: 500px;
  }
  @media (max-width: 768px) {
    height: 550px;
    padding-top: 15px;
  }
  @media (max-width: 576px) {
    height: 550px;
    padding-top: 15px;
  }
  @media (max-width: 452px) {
    height: 550px;
    padding-top: 15px;
  }
}

.who-are-we-h2{
  font-size: clamp(44px, 2.8125vw, 54px);
 

  @media (max-width: 10000px) {
    font-size: 44px;
  }
  @media (max-width: 1980px) {
    font-size: 44px;
  }
  @media (max-width: 1600px) {
    font-size: 44px;
  }
  @media (max-width: 1400px) {
    font-size: 44px;
  }
  @media (max-width: 1200px) {
    
  }
  @media (max-width: 1024px) {
    
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
}
.paragraph {
    opacity: 0;
    transform: translateY(100%);
    transition: all 1s ease-out;
}
.team-paragraph {
  line-height: 1.7;
  text-align: center;
  margin-top: 50px;
  letter-spacing: -0.44px;

  .light-span {
    font-size: 32px;
    font-weight: 300;
    color: #ffffff;
    @media (max-width: 10000px) {
      font-size: 32px;
    }
    @media (max-width: 1980px) {
      font-size: 1.563vw;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    
  }

  .bold-span {
    font-size: 40px;
    font-weight: 500;
    color: #ffffff;
    @media (max-width: 10000px) {
      font-size: 40px;
    }
    @media (max-width: 1980px) {
      font-size: 2.083vw;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .muted-span {
    font-size: 32px;
    font-weight: 300;
    color: #ffffff;
    opacity: 0.5;
    @media (max-width: 10000px) {
      font-size: 32px;
    }
    @media (max-width: 1980px) {
      font-size: 1.563vw;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}


/* Who are we section transition/animate */
@keyframes slideDown {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.animate-slide-down {
  animation: slideDown 1.5s ease-out forwards;
  transition: transform 1.5s ease-out, opacity 1.5s ease-out;
}
.animate-slide-up {
  animation: slideUp 1.5s ease-out forwards;
  transition: transform 1.5s ease-out, opacity 1.5s ease-out;
}