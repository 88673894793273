/*===========================
17. Page Index Style CSS
=============================*/

.page-header-area {
  .page-header-content-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #3A3A3A;
    text-align: left;

    @media (max-width: 10000px) {
      padding-top: 270px;
      padding-bottom: 150px;
    }
    @media (max-width: 1600px) {
     padding-top: 14.063vw;
     padding-bottom: 7.813vw;
    }
    @media (max-width: 1440px) {
      padding-top: 220px;
     padding-bottom: 150px;
    }
    @media (max-width: 1200px) {
      padding-top: 170px;
     padding-bottom: 100px;
    }
  
    @media (max-width: 1024px) {
     
    }
  
    @media (max-width: 768px) {
      
    }
  
    @media (max-width: 576px) {
      
    }
  
    @media (max-width: 420px) {
      
    }
    @media #{$md-device} {
      height: 100%;
    }

    @media #{$sm-device} {
      height: 100%;
    }

    h1 {
      color: $lightGrayishBlue;
      font-weight: 600;

      @media (max-width: 10000px) {
        font-size: 64px;
      }
      @media (max-width: 1980px) {
        font-size: 3.3333vw;
      }
      @media (max-width: 1600px) {
        font-size: 3.3333vw;
    
      }
      @media (max-width: 1400px) {
        font-size: 3.3333vw;
      }
      @media (max-width: 1200px) {
        
      }
      @media (max-width: 1024px) {
        
      }
      @media (max-width: 991px) {
        font-size: 46px;
        margin-bottom: 30px;
      }
    }

    h2 {
      color: $white;
      // text-transform: uppercase;
      @include font-size(60px);

      @media #{$xs-device} {
        font-size: 30px;
      }
    }

    p {
      @media #{$xl-device} {
        color: $lightGrayishBlue;
        // margin: auto;
        // font-size: 16px;
        // font-weight: 600;
      }
    }
  }
}

.breadcrumb-wrap {
  margin-top: 25px;

  @media #{$sm-device} {
    margin-top: 15px;
  }

  .breadcrumb {
    background-color: transparent;
    border-radius: 0;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {

      &:last-child {
        a {
          &:after {
            display: none;
          }
        }
      }

      a {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        position: relative;

        &:after {
          content: '-';
          margin: 0 20px;
          pointer-events: none;

          @media #{$sm-device} {
            margin: 0 10px;
          }
        }

        &.current {
          pointer-events: none;
          opacity: 0.8;
        }
      }
    }
  }
}